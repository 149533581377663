import React from 'react';
import { GoArrowUpRight } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { cn } from '../utils/utils';

const ScheduleBtn = ({isPrimary,className,}:{ isPrimary?: boolean, className?: string }) => {
  return (
    <Link
      className={cn("flex max-w-fit  gap-2.5 cursor-pointer items-center rounded bg-white px-4 py-2 text-sm text-primary shadow", isPrimary &&"bg-primary text-white", className)}
      to="/schedule"
    >
      Schedule a Demo <GoArrowUpRight className={isPrimary ? "text-white" : "text-primary"} />
    </Link>
  );
};

export default ScheduleBtn;
