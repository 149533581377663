import Carousel from 'react-multi-carousel';

import FadeInWrapper from '../components/FadeInWrapper';
import Footer from '../components/footer';
import Terms from '../components/Terms';
import useIsMobile from '../hooks/isMobile';
import { featuresCard } from '../pages/contents/home-content';

const featureResponsive = {
  desktop: {
    breakpoint: { max: 1920, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 512 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 512, min: 0 },
    items: 1,
  },
};

export default function Feature() {
  const screenSize = useIsMobile();
  return (
    <>
      <div className="w-full overflow-x-hidden">
        <FadeInWrapper
          direction="left"
          className="mx-auto my-32 mb-20 ml-10 flex flex-col gap-3 px-4 max-sm:text-4xl"
        >
          {/* Heading Section */}
          <p className="text-xl text-[#3b3b3b]">Features</p>
          <h1 className="max-w-[30ch] text-[#3B3B3B] text-3xl md:text-3xl">
            Discover Our <i className="font-serif text-[1.2em]">Key Features</i>
          </h1>

          <p className="max-w-[70ch] text-sm text-gray-500 ">
            Enhance efficiency, accuracy, and gain complete control over your
            financial management with our comprehensive solutions.
          </p>
        </FadeInWrapper>
        <div className="relative h-[760px] bg-feature-texture bg-cover bg-no-repeat">
          <div className="absolute top-1/2 w-full -translate-y-1/2 p-5">
            <Carousel
              responsive={featureResponsive}
              arrows={false}
              showDots={screenSize === 'mobile'}
              autoPlay
              infinite
              pauseOnHover
            >
              {featuresCard.map((card) => (
                <div
                  key={card.id}
                  className="relative h-[20rem] w-[90%] mx-auto   overflow-hidden rounded-2xl bg-white/10 p-8 backdrop-blur"
                >
                  <p className="text-[20px] text-white">{card.title}</p>
                  <p className="mt-4 text-[12px] text-[#E4E4E4]">{card.text}</p>
                  <img
                    src={card.image}
                    alt={card.alt}
                    className="absolute bottom-0 right-0 h-[140px] w-[120px] rounded-2xl"
                  />
                </div>
              ))}
            </Carousel>
          </div>
        </div>

        <div className="sm:mt-30 mt-20 flex justify-center px-4 text-center font-serif tracking-wider text-slate-700 sm:px-8 md:mt-40 lg:px-0">
          <h1 className="text-4xl max-sm:text-2xl">
            <i
              className="text-4xl max-sm:text-2xl"
              style={{
                fontFamily: 'Playfair Display, serif',
                fontStyle: 'italic',
              }}
            >
              Experience
            </i>{' '}
            the Difference <br /> with Neo CFO
          </h1>
        </div>

        <div className="mt-14 flex flex-col-reverse items-center justify-center gap-8 lg:flex-row lg:gap-40">
          {/* Text Section */}

          <FadeInWrapper direction="left" className="flex flex-col">
            {/* Title */}
            <div className="l mx-9 mt-16 text-[#3B3B3B] sm:mx-0 sm:ml-16 sm:mt-24 md:mt-32">
              <div className="pb-3 text-[28px]">Expert support:</div>
              <div className="-translate-y-3 text-[28px]">
                Your partner in success
              </div>
            </div>

            {/* Description */}
            <div className="mt-1 flex justify-center sm:mt-1">
              <p
                className=" w-[80vw] text-start text-[#829392] sm:ml-16 sm:text-left md:w-[480px]"
                style={{ fontSize: '16px' }}
              >
                Join the growing number of businesses that trust Neo CFO to
                optimize their financial operations and drive success.
              </p>
            </div>
          </FadeInWrapper>
          {/* Image Section */}
          <FadeInWrapper
            direction="right"
            style={{ backgroundColor: '#DAEEBD' }}
            className="mt-10 flex h-[400px] w-[80%] max-w-lg items-end justify-center rounded-lg sm:mt-16 md:w-[400px] lg:mt-20 lg:justify-end"
          >
            <img
              src="./invoice.webp"
              // className="h-[87%] w-[90%] md:w-[350px]"
              alt="Invoice"
            />
          </FadeInWrapper>
        </div>
        <div className="z-0" style={{ zIndex: '0' }}>
          <Terms />
        </div>

        <Footer />
      </div>
    </>
  );
}
