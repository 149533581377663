import "../css/home.css";

import { FaQuoteRight } from "react-icons/fa6";
import { GoArrowUpRight } from "react-icons/go";
import Carousel from "react-multi-carousel";
import { Link } from "react-router-dom";

import CarouselContent from "../components/CarouselContent";
import FadeInWrapper from "../components/FadeInWrapper";
import Footer from "../components/footer";
import Navbar from "../components/header";
// import HomeBlogCard from '../components/HomeBlogCard';
import NeoCfo from "../components/neocfo";
// import ReadMoreBtn from '../components/ReadmoreBtn';
import ScheduleBtn from "../components/ScheduleBtn";
import Terms from "../components/Terms";
import useIsMobile from "../hooks/isMobile";
import { cn } from "../utils/utils";
import { homeCard, testimonials } from "./contents/home-content";

const featureResponsive = {
  desktop: {
    breakpoint: { max: 1920, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 512 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 512, min: 0 },
    items: 1,
  },
};

export default function Home() {
  const screenSize = useIsMobile();

  return (
    <>
      <div className="space-y-20 overflow-x-hidden">
        <section className="bg-hero-texture bg-cover bg-no-repeat pt-20">
          <Navbar />
          {/* Hero Section */}
          <div className="flex flex-col items-end gap-12 pb-24 pl-8 pt-16 md:flex-row-reverse md:gap-4 md:pl-10 lg:pl-20">
            <FadeInWrapper
              className="relative w-full md:w-1/2"
              direction="right"
            >
              <img
                src="./87e2fdadeb7913c22c77c8da3e3b97a3.webp"
                className="w-[96vw] max-w-[900px] translate-x-[5%]"
                alt="hero image"
              />
            </FadeInWrapper>
            <FadeInWrapper className="w-full space-y-2 text-white md:w-1/2 md:space-y-5">
              <h1 className="font-serif text-[2rem] italic leading-10 drop-shadow-sm md:text-[2.625rem] lg:text-6xl 2xl:text-7xl">
                Revolutionize your <br /> Financial Processes
              </h1>
              <h2 className="text-2xl md:text-3xl lg:text-5xl">with Neo CFO</h2>
              <p className="max-w-[40ch] text-sm md:text-base">
                Streamline Accounting, Finance and Taxation with our
                cutting-edge automation platform with our best-in-class
                Integrations
              </p>
              <ScheduleBtn />
            </FadeInWrapper>
          </div>
          <div className="bg-white/30 p-6 backdrop-blur-sm md:p-8 lg:py-10 filter drop-shadow-md">
            <div className="flex items-center justify-center gap-0 md:gap-12 filter saturate-50">
              <img
                src="/aws_startups.png"
                alt="aws logo"
                className="m-0 h-6 border-r-4 border-primary/5 pr-5 md:h-8 md:pr-12 lg:h-11"
              />
              <img
                src="/microsoft_startups.png"
                alt="microsoft logo"
                className="m-0 h-6 border-r-4 border-primary/5 pr-5 md:h-8 md:pr-12 lg:h-11"
              />
              <img
                src="/nvidia_startups.png"
                alt="nvidia logo"
                className="m-0 h-6 md:h-8 lg:h-11"
              />
            </div>
          </div>
        </section>

        {/* Our story */}
        <section className="mx-auto w-[90%] space-y-5">
          <p className="text-[20px]">Our Story</p>
          <p className="text-2xl md:text-[1.9rem]">
            Neo CFO was founded with a mission to <br />
            <i className="font-serif text-[1.2em]">
              simplify financial management
            </i>{" "}
            for modern businesses.
          </p>
          <Link
            className="flex w-fit items-center justify-center gap-2.5 rounded-full bg-black/5 px-4 py-2.5 text-xs text-primary/60 backdrop-blur-sm hover:bg-black/10"
            to={"/story"}
          >
            Know more <GoArrowUpRight />
          </Link>
          <FadeInWrapper direction="bottom">
            {screenSize === "mobile" ? (
              <div className="flex flex-col items-center justify-center gap-12">
                <div className="flex flex-col gap-8">
                  <img src="cfo1.webp" alt="" className="rounded-lg" />
                  <div>
                    <h3 className="mb-1 text-sm">What is Neo CFO?</h3>
                    <p className="text-xs text-gray-500">
                      Neo CFO is an advanced financial automation platform
                      designed to streamline and simplify finance management for
                      businesses of all sizes. From automating accounting
                      entries, GST taxation, and procure-to-pay processes, to
                      integrating seamlessly with major accounting platforms and
                      government portals.
                    </p>
                  </div>
                </div>

                <div className="flex flex-col gap-8">
                  <img src="cfo2.webp" alt="" className="rounded-lg" />
                  <div>
                    <h3 className="mb-1 text-sm">Our Mission</h3>
                    <p className="text-xs text-gray-500">
                      Our mission is to shift the ratio from spending 80% of
                      time on data preparation to allocating 80% of time to
                      meaningful data insights and financial analysis.
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <NeoCfo />
            )}
          </FadeInWrapper>
        </section>

        {/* features sections */}
        <section className="mx-auto flex w-[90%] flex-col items-center gap-6 text-center">
          <h1 className="text-3xl md:text-4xl">
            Empower Your{" "}
            <i className="font-serif text-[1.2em]">Finance Operations</i>
            <br />
            with NEO CEO
          </h1>

          <FadeInWrapper
            direction="left"
            className="mx-auto max-w-[40ch] text-xs text-gray-400 md:max-w-[70ch] md:text-sm"
          >
            At Neo CFO, we bring advanced automation to your finance operations,
            enabling seamless and efficient management of complex financial
            processes. Here are the top features that make Neo CFO the go-to
            platform for businesses.
          </FadeInWrapper>

          <Link
            className="flex items-center justify-center gap-2.5 rounded-full bg-black/5 px-6 py-2.5 text-sm text-primary/60 backdrop-blur-sm hover:bg-black/10"
            to={"/feature"}
          >
            Learn more <GoArrowUpRight />
          </Link>

          <FadeInWrapper direction="right" className="w-full">
            <Carousel
              responsive={featureResponsive}
              arrows={false}
              showDots={screenSize === "mobile"}
              autoPlay
              infinite
              pauseOnHover
            >
              {homeCard.map(card => (
                <img
                  src={card.image}
                  key={card.id}
                  alt={card.alt}
                  className={cn(
                    "w-full px-1.5 md:mx-4",
                  )}
                />
              ))}
            </Carousel>
          </FadeInWrapper>
        </section>

        {/* reviews section */}
        <section className="mx-auto w-[90%] items-center">
          <FadeInWrapper
            direction="left"
            className="mb-10 text-2xl text-primary md:ml-[3rem] md:text-3xl md:leading-[1.3]"
          >
            What Our Customers Are Saying: <br />
            <i className="font-serif text-[1.2em]">Real Success Stories</i> with
            Neo CFO
          </FadeInWrapper>

          <FadeInWrapper
            direction="bottom"
            id="success-container"
            className="p-4"
          >
            <CarouselContent key={"story-carousel"}>
              {testimonials.map((testimonial, index) => (
                <div
                  className="m-1.5 flex h-[280px] flex-col justify-between gap-3 rounded-md border-2 border-transparent bg-white/20 px-4 py-12 backdrop-blur-xl transition-all duration-300 hover:cursor-pointer hover:bg-white/30 md:h-[240px] md:px-8"
                  key={index}
                >
                  <div className="relative flex items-center gap-1.5">
                    <div className="">
                      <h3 className="font-serif text-2xl text-primary/60">
                        {testimonial.name}
                      </h3>
                      <p className="text-sm text-[#2C766F]">
                        {testimonial.title}
                      </p>
                    </div>
                    <FaQuoteRight className="absolute right-0 top-0 text-4xl text-white/50" />
                  </div>
                  <p className="text-sm text-primary/60">{testimonial.text}</p>
                </div>
              ))}
            </CarouselContent>
          </FadeInWrapper>
        </section>

        {/* blogs */}
        {/* <section className="space-y-8 bg-primary px-4 py-12 text-white md:px-8">
          <div className="space-y-2">
            <p>Blogs</p>
            <div className="mb-6 flex items-end justify-between">
              <h2 className="text-2xl md:text-3xl">
                Insights & Innovations: <br />
                Let’s Explore the
                <i className="font-serif text-[1.2em]"> Future of Finance</i>
              </h2>
              <div className="hidden md:block">
                <ReadMoreBtn to="blogpage" />
              </div>
            </div>
          </div>

          <div className="grid max-w-screen-2xl grid-cols-1 gap-4 md:grid-cols-3 md:justify-start lg:gap-8">
            <HomeBlogCard
              image="ExploreMore1.jpeg"
              text="Top 5 Ways to Revolutionize Your Accounting Processes"
              readTime={5}
            />
            <HomeBlogCard
              image="ExploreMore3.jpeg"
              text="The Ultimate Guide to Maximizing Efficiency"
              readTime={10}
            />
            <HomeBlogCard
              image="ExploreMore2.jpeg"
              text="The Future of Financial Automation"
              readTime={5}
            />

            <div className="ml-auto block md:hidden">
              <ReadMoreBtn to="blogpage" />
            </div>
          </div>
        </section> */}
        <FadeInWrapper direction="top">
          <Terms />
        </FadeInWrapper>
        <Footer />
      </div>
    </>
  );
}
